import React, { useEffect, useState, useRef } from "react";
import Author from "../assets/images/product52.jpg";
import Card from "../views/Card.js";
import Tick from "../assets/images/svg/tick.svg";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { NavLink, Link, useLocation, useNavigate } from "react-router-dom";
import Header from "../separate/Header.js";
import Footer from "../separate/Footer.js";
import Countdown from "react-countdown";
import Modals from "./Modals";
import { useSelector } from "react-redux";
import {
  CollectionByCreator,
  HotAuctionHotSales,
  Token_List_Func,
  TopCreatorApi,
} from "../actions/axioss/nft.axios";
import { getCmsContent, getarticle } from "../actions/axioss/cms.axios";
import { address_showing, axiosFunc } from "../actions/common";
import config from "./config/config";
import ImgAudVideo from "../separate/ImgAudVideo";
import NoData from "./seperatemodals/nodata";
import { SearchAction } from "../actions/axioss/user.axios";
import { Getpromotedtoken } from "../actions/axioss/nft.axios";
import { data } from "jquery";
import { createImg } from "../actions/axioss/user.axios";

// import { midjourney } from "../actions/axioss/user.axios";
import axios from "axios";
import faqimg from "../assets/images/galaxy-7040416__480.webp";
import rocket from "../assets/images/RocketLaunch.png";
import aiiimg from "../assets/images/aiimg.png";
import placee from "../assets/images/Imageplaceholder.png";
import wing1 from "../assets/images/pngwing 1.png";
import wing2 from "../assets/images/pngwing 2.png";

import author from "../assets/images/Artist1.png";
import KittyTeamCard from "./KittyTeamCard.js";
import Marquee from "react-fast-marquee";
import Lottie from "lottie-react";
import YellowRound from '../assets/images/kitty/lotties/Sat_Round.json'
import VioletRouned from '../assets/images/kitty/lotties/galaxy.json'
import UFO from '../assets/images/kitty/lotties/UFO_round.json'
import CardLottie from '../assets/images/kitty/lotties/Card.json'
import VenusRound from '../assets/images/kitty/lotties/Ball.json'
import Star from '../assets/images/kitty/lotties/Star.json'
import Gradient from '../assets/images/kitty/lotties/Gradien.json'

// import { Web3Auth } from "@web3auth/modal";

export default function Home() {
  const [show, setShow] = React.useState(false);
  const [hotactionstate, SetHotAuctionstate] = useState(true);
  const [hotsalestate, SetHotsalestate] = useState(true);
  const [bannerstate, setBannerstatus] = useState(true);
  const { isAdmin } = useSelector((state) => state.LoginReducer.User);
  const navigate = useNavigate();
  const payload = useSelector((state) => state.LoginReducer.AccountDetails);
  // const [ishome,setIshome] = React.useState(false);

  // const { state , pathname} = useLocation();
  // useEffect(()=>
  // {
  //     setIshome(state?true:false);
  //     if(ishome)
  //     {
  //         var ele = document.getElementById("liveauction");
  //         console.log(ele,"ele");
  //         if(ele)
  //         {
  //             if(ele != null && ele !="undefined")
  //             {
  //                 window.scrollTo(0,ele.offsetTop)

  //             }
  //         }
  //         setIshome(false)
  //     }
  //     else
  //     {
  //         alert(1)
  //         // state
  //         window.scrollTo(0,0)
  //     }

  //     // return () => state = null

  // },[state])

  // const pull_data = (data,click) => {
  //     console.log(data,click,"all data");
  //     LOGS DATA FROM CHILD (My name is Dean Winchester... &)
  //     var ele = document.getElementById(data);
  //     console.log(ele,"ele");
  //     if(click && data)
  //     {
  //         if(ele != null && ele !="undefined")
  //         {
  //             window.scrollTo(0,ele.offsetTop)

  //         }
  //     }

  //   }

  const collection = [
    {
      img: Author,
      title: "SweetGirlofCandy",
      createdName: "MariaBrownie@1123",
    },
    {
      img: Author,
      title: "SweetGirlofCandy",
      createdName: "MariaBrownie@1123",
    },
    {
      img: Author,
      title: "SweetGirlofCandy",
      createdName: "MariaBrownie@1123",
    },
    {
      img: Author,
      title: "SweetGirlofCandy",
      createdName: "MariaBrownie@1123",
    },
    {
      img: Author,
      title: "SweetGirlofCandy",
      createdName: "MariaBrownie@1123",
    },
    {
      img: Author,
      title: "SweetGirlofCandy",
      createdName: "MariaBrownie@1123",
    },
    {
      img: Author,
      title: "SweetGirlofCandy",
      createdName: "MariaBrownie@1123",
    },
    {
      img: Author,
      title: "SweetGirlofCandy",
      createdName: "MariaBrownie@1123",
    },
    {
      img: Author,
      title: "SweetGirlofCandy",
      createdName: "MariaBrownie@1123",
    },
    {
      img: Author,
      title: "SweetGirlofCandy",
      createdName: "MariaBrownie@1123",
    },
  ];
  const slideone = {
    dots: false,
    infinite: true,
    smartSpeed: 4000,
    autoplayHoverPause: true,
    autoplay: true,
    // loop:true,
    nav: false,
    margin: 30,
    speed: 1500,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  const options1 = {
    loop: true,
    margin: 20,
    navText: [
      "<i class='fa fa-chevron-left'></i>",
      "<i class='fa fa-chevron-right'></i>",
    ],
    nav: true,
    dots: false,
    smartSpeed: 1200,
    autoplay: false,
    responsive: {
      0: {
        items: 1,
      },
      576: {
        items: 2,
      },
      768: {
        items: 2,
      },
      992:{
        items: 3,
      },
      1200:{
        items: 4,
      },
      1500:{
        items: 4,
      },
      1720:{
        items: 4,
      },
      
    },
  };
  const [blog, setBlog] = useState([]);
  const [aboutlist1, Setaboutlist1] = useState([
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
    {
      id: 4,
    },
    {
      id: 5,
    },
    {
      id: 6,
    },
    {
      id: 7,
    },
    {
      id: 8,
    },
  ]);

  const slidetwo = {
    dots: false,
    infinite: true,
    smartSpeed: 3500,
    autoplayHoverPause: true,
    autoplay: true,
    loop: true,
    nav: false,
    margin: 30,
    speed: 1500,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };
  var renderer = ({
    days,
    Month,
    Year,
    hours,
    minutes,
    seconds,
    completed,
  }) => {
    if (completed) {
      return <span>Waiting for Owner To Accept</span>;
    } else {
      return (
        <span>
          {" "}
          <span className="hourds">{formatTime(days)} d</span>{" "}
          <span className="semicolan">:</span>{" "}
          <span className="hourds">{formatTime(hours)} h</span>{" "}
          <span className="semicolan">:</span>{" "}
          <span className="hourds">{formatTime(minutes)} m</span>{" "}
          <span className="semicolan">:</span>{" "}
          <span className="hourds">{formatTime(seconds)} s</span>{" "}
        </span>
      );
    }
  };
  const formatTime = (time) => {
    return String(time).padStart(2, "0");
  };

  /** lp */
  const { Categorys } = useSelector((state) => state.LoginReducer);

  const [creatorstatus, setCreator] = useState(false);
  const [TopCreator, SetTopCreator] = useState([]);

  var LikeForwardRef = useRef();
  const [LikedTokenList, setLikedTokenList] = useState([]);

  const [homecontent, setHomecontent] = useState([]);
console.log("dfgbdhgdfh",homecontent);
  const [homecontentmidd, setHomecontentmidd] = useState([]);
console.log("homepagessss",homecontentmidd)
  console.log(":payload", payload);

  const [HotAuctionData, SetHotAuction] = useState({
    All: [],
    tab: "All",
  });
  const [SaleData, SetSaleData] = useState({
    All: [],
  });
  const [category, setCategory] = useState("All");
  const [CreateCollectionState, SetCreateCollectionState] = useState({
    All: [],
    tab: "All",
  });
  const [val, Setval] = useState("");
  const [blogstate, setblogstate] = useState(false);
  const [Searchdata, SetSearch] = React.useState(null);
  const [filter, setFilter] = useState({
    auction: "Recent",
    collection: "new",
    sale: "LatestDrops",
  });
  var [filterview, setFilterview] = useState({
    auction: "Recently created",
    collection: "Recently created",
    sale: "Recently created",
  });
  var [promotedToken, setPromotedtoken] = useState({});

  // const [homecontent,setHomecontent]=useState([]);
  // const[homecontentmidd,setHomecontentmidd]=useState([])
  // console.log("------------------",category);

  useEffect(() => {
    getarticles();
    Getandselldata();
    Getandsellnft();
    TopCreatorFunc();
    HotAuction(1, "All");
    HotSales("All");
    // Collectionlist('All')
    CollectionByCreate("All");
    GetPromtion();
  }, []);

  const CollectionByCreate = async (data) => {
    var SendDATA = {
      tab: data,
      limit: 4,
      ProfileUrl: "",
      page: 1,
      from: "home",
      filter: filter.collection,
    };
    let Resp = await CollectionByCreator(SendDATA);
    // console.log('fhgngfngf',Resp)
    SetCreateCollectionState({
      ...CreateCollectionState,
      ...{
        [data]: Resp?.data,
        tab: data,
        filter: filter.collection,
      },
    });
    setCategory(data);
  };

  const getarticles = async () => {
    setblogstate(false);
    var resp = await getarticle({ skip: 0, limit: 12 });
    if (resp?.status) {
      setBlog(resp?.data);
      setblogstate(true);
    }
  };
  const TopCreatorFunc = async () => {
    var resp = await TopCreatorApi();
    console.log("topcreator", resp);
    if (resp?.data?.length > 8) {
      SetTopCreator(resp?.data.slice(0, 8));
      setCreator(true);
    } else {
      SetTopCreator(resp?.data);
      setCreator(true);
    }
  };
  // console.log("HotAuctionData",HotAuctionData)
  const HotAuction = async (data, tabs) => {
    var SendDATA = {
      TabName: tabs,
      limit: 8,
      ProfileUrl: "",
      page: 1,
      from: "Auction",
      filter: filter.auction,
    };
    let Resp = await Token_List_Func(SendDATA);
    if (Resp?.success == "success") {
      SetHotAuction({
        ...HotAuctionData,
        ...{
          [tabs]: Resp?.data,
          tab: tabs,
          filter: filter.auction,
        },
      });
      SetHotAuctionstate(false);
    } else {
      SetHotAuction({
        ...HotAuctionData,
        ...{
          [tabs]: Resp?.data,
          tab: tabs,
          filter: filter.auction,
        },
      });
      SetHotAuctionstate(false);
    }
  };
  const HotSales = async (tabs) => {
    var SendDATA = {
      TabName: tabs,
      limit: 8,
      ProfileUrl: "",
      page: 1,
      from: "Sale",
      filter: filter.sale,
    };
    let Resp = await Token_List_Func(SendDATA);
    console.log("hot sales", Resp, "tabs", tabs);
    if (Resp?.success == "success") {
      SetSaleData({
        ...SaleData,
        ...{
          [tabs]: Resp?.data,
          tab: tabs,
          filter: filter.sale,
        },
      });
      SetHotsalestate(false);
    } else {
      SetSaleData({
        ...SaleData,
        ...{
          [tabs]: Resp?.data,
          tab: tabs,
          filter: filter.sale,
        },
      });
      SetHotsalestate(false);
    }
  };

  function LikeList(data) {
    setLikedTokenList(data);
  }

  const Collectionlist = async (tabs) => {
    var SendDATA = {
      TabName: tabs,
      limit: 4,
      ProfileUrl: "",
      page: 1,
      from: "collection",
    };
    let Resp = await Token_List_Func(SendDATA);
    if (Resp?.success == "success")
      setCategory({
        ...SaleData,
        ...{
          [tabs]: Resp?.data,
          tab: tabs,
        },
      });
  };

  const Getandselldata = async () => {
    var resp = await getCmsContent("homepage_top");
     console.log("cmslistdfgfd",resp.data)
    if (resp?.status) setHomecontent(resp.data);
  };
  const Getandsellnft = async () => {
    var resp = await getCmsContent("homepage_middle");
    console.log("cmslist", resp?.data);
    if (resp?.status) setHomecontentmidd(resp?.data);
  };

  const OnChange = async (value) => {
    // console.log("vallllllllllll",value);
    if (value) {
      Setval(value);
      var Resp = await SearchAction({
        keyword: value,
        limit: 3,
        page: 1,
        from: "home",
      });
      // console.log("response", Resp);
      if (Resp?.success === "success") {
        SetSearch(Resp);
      } else {
        SetSearch(null);
      }
    } else {
      SetSearch(null);
      Setval("");
    }
  };

  const GetPromtion = async () => {
    // var resp = await Getpromotedtoken()
    var protoken = await Getpromotedtoken();
    console.log("DGJFGJFJD", protoken);
    if (protoken?.success == "success") {
      console.log(protoken?.data[0], "protoken");
      setPromotedtoken(protoken?.data[0]);
      setBannerstatus(false);
    }
  };

  useEffect(() => {
    // if(filter?.auction)
    if (
      HotAuctionData.filter !== undefined &&
      filter.auction !== HotAuctionData.filter
    ) {
      HotAuction("hi", HotAuctionData.tab);
    }
    if (SaleData.filter !== undefined && filter.sale !== SaleData.filter) {
      HotSales(SaleData.tab);
    }
    if (
      CreateCollectionState.filter !== undefined &&
      filter.collection !== CreateCollectionState.filter
    ) {
      CollectionByCreate(CreateCollectionState.tab);
    }
  }, [filter]);

  const AuctionFilter = (val, show) => {
    setFilter({ ...filter, ...{ auction: val } });
    setFilterview({ ...filterview, ...{ auction: show } });
  };

  const SaleFilter = (val, show) => {
    setFilter({ ...filter, ...{ sale: val } });
    setFilterview({ ...filterview, ...{ sale: show } });
  };

  const CollectionFilter = (val, show) => {
    setFilter({ ...filter, ...{ collection: val } });
    setFilterview({ ...filterview, ...{ collection: show } });
  };
  const midjourney = async (msg) => {
    try {
      var data = JSON.stringify({
        msg: "msg",
        ref: "",
        webhookOverride: "",
        ignorePrefilter: "false",
      });

      var config = {
        method: "post",
        url: "https://api.thenextleg.io/v2/imagine",
        headers: {
          Authorization: "Bearer 014ad4ad-d99f-42a3-8539-d61da39d8d99",
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config).then(async function (response) {
        console.log("IMAGEMESSAGEID", JSON.stringify(response.data));

        if (response.data.success == true) {
          var datas = await geturl(response.data.messageId);

          if (datas) {
            console.log("imgurlllll", datas);
          }
        }
      });
    } catch (err) {
      console.log("ERRRRRRRR", err);
    }
  };

  const geturl = async (id) => {
    var progress;
    var retdata;

    do {
      var resp = await createImg({ id: id });
      progress = resp.data.progress;
      console.log(progress, resp);
    } while (Number(progress) != 100);

    return retdata;
  };

  useEffect(() => {
    const preventContextMenu = (event) => {
      event.preventDefault();
    };

    const images = document.querySelectorAll("img");

    images.forEach((image) => {
      image.addEventListener("contextmenu", preventContextMenu);
    });

    return () => {
      images.forEach((image) => {
        image.removeEventListener("contextmenu", preventContextMenu);
      });
    };
  }, []);


  const marqueeTop = [
    {
      img : require('../assets/images/kitty/huboi.svg').default,      
    },
    {
      img : require('../assets/images/kitty/gemini.svg').default,      
    },
    {
      img : require('../assets/images/kitty/ethereum.svg').default,      
    },
    {
      img : require('../assets/images/kitty/payeer.svg').default,      
    },
    {
      img : require('../assets/images/kitty/bitsquare.svg').default,      
    },
    {
      img : require('../assets/images/kitty/ribble.svg').default,      
    },
    {
      img : require('../assets/images/kitty/binance.svg').default,      
    },
  ]

  // export const nested = async(req,res)=>{
  //     var data = await Emp.aggregate([
  //       {$lookup:{
  //         from:"users",
  //         let:{"id":"$email"},
  //         pipeline:[{$match:{$expr:{$eq:["$email","$$id"]}}},
  //        {$lookup:{
  //         from:"emps",
  //         let:{"val":"$name"},
  //         pipeline:[{$match:{$expr:{$eq:["$name","$$val"]}}}],
  //         as:"result"
  //        }},
  //       ],
  //       as:"data"

  //       }}
  //     ])
  //    }

  // document.oncontextmenu = function () {
  //     return false;
  // }

  return (
    <>
      <div className="homepage" id="homescreen" oncontextmenu="return false;">
        <Header />
        {/* <img src="" id="photo" /> */}
        <div className="modals">
          <Modals />
        </div>
        <section className="tf-slider kitty__homeBanner">
        <Lottie animationData={YellowRound} className="kitty__homeYellowRound" loop={true} />
        <Lottie animationData={VioletRouned} className="kitty__homeVioletRound" loop={true} />
        <Lottie animationData={Gradient} className="kitty__homeUFO" loop={true} />
        <img src={require('../assets/images/kitty/merger.png')} className="img-fluid kitty__bannerMerger" />
          <div className="tf-container">
            <div className="row">
              <div className="col-md-12">
                <div className="swiper-container slider-home ">
                  <div className="tf-slider-item style-4 top-banner">
                    <div className="row align-items-center justify-content-between mt-5 w-100">
                      <div className="col-md-6 col-xl-5 kitty__bannerLeft">
                        {/* <div className="first-p" dangerouslySetInnerHTML={{ __html: homecontent?.answer }}></div> */}
                        <div>
                          <p className="home_banne_tex">
                            <div
                              className="first-p workSans"
                              dangerouslySetInnerHTML={{
                                __html: homecontent?.description,
                              }}
                            ></div>
                          </p>
                          <p className="home_banne_tex_dec">
                            <div
                              className="first-p"
                              dangerouslySetInnerHTML={{
                                __html: homecontent?.answer,
                              }}
                            ></div>
                          </p>
                        </div>
                        <div className="btn-slider exploretop mt-5">
                          {/* <input type="button" onClick={() => midjourney("cat and dog fight")} value="Midjourney" /> */}
                          <NavLink to="/explore" className="tf-button style-2">
                            <img
                              src={require('../assets/images/kitty/rocket.svg').default}
                              alt="images"
                              className="img-fluid rocketleft"
                            />
                            Explore
                          </NavLink>
                        </div>
                      </div>
                      <div className="col-md-6 col-xl-5 kitty__bannerRight">

        <Lottie animationData={CardLottie} className="kitty__homeCardLottie" loop={true} />
                        <div className="kitty__banner d-flex justify-content-end align-items-center">
                        <img src={require('../assets/images/kitty/bannercat.png')} className="img-fluid kitty__bannerRightImg" />
                        </div>
                        {/* <div class="card firstsec mt-3">
                          {promotedToken?.CompressedFile ? (
                            <Link
                              to={`/info/${promotedToken?.CollectionNetwork}/${promotedToken?.ContractAddress}/${promotedToken?.NFTOwner}/${promotedToken?.NFTId}`}
                            >
                              <ImgAudVideo
                                file={`${config.IMG_URL}/nft/${promotedToken?.NFTCreator}/Compressed/NFT/${promotedToken?.CompressedFile}`}
                                origFile={`${config.IMG_URL}/nft/${promotedToken?.NFTCreator}/Original/NFT/${promotedToken?.OriginalFile}`}
                                thumb={`${config.IMG_URL}/nft/${promotedToken.NFTCreator}/Compressed/NFT_THUMB/${promotedToken?.CompressedThumbFile}`}
                                type={
                                  promotedToken?.CompressedFile
                                    ? promotedToken?.CompressedFile?.includes(
                                        ".webp"
                                      )
                                      ? "image"
                                      : promotedToken?.CompressedFile.includes(
                                          ".webm"
                                        )
                                      ? "video"
                                      : "audio"
                                    : promotedToken?.CompressedFile
                                }
                              />
                              <div class="card-body">
                                <h4 class="card-title">Auction Ends In</h4>
                                <span className="js-countdown countdown style-3 countdown_value">
                                  <Countdown
                                    date={
                                      promotedToken?.EndClockTime
                                        ? promotedToken?.EndClockTime
                                        : Date.now() + 8000000
                                    }
                                    autoStart={true}
                                    renderer={renderer}
                                  />
                                </span>
                              </div>
                            </Link>
                          ) : (
                            <img
                              src={placee}
                              alt="d"
                              classNameName="img-fluid"
                            />
                          )}
                         
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="kitty__aboutSec higher__top higher__tooBottom">
          <img src={require('../assets/images/kitty/aboutCloud.png')} className="img-fluid kitty__aboutCloud" />
          <div className="tf-container">
          <div className="row align-items-center">
            <div className="col-lg-6">

        <Lottie animationData={VenusRound} className="kitty__venusRound" loop={true} />
                        <img src={require('../assets/images/kitty/sunkitty.png')} className="img-fluid kitty__homesunImg" />
            </div>
            <div className="col-lg-6 higher__top">
              <h5 className="m-0 kitty__homeTitles workSans">About Our Kitty Plant</h5>

              <p className="kitty__homeSunHint m-0 mt-5">In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available.</p>
              <p className="kitty__homeSunHint m-0 mt-5">In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available.</p>
            </div>
          </div>
          </div>  
        </section>

       

        <section
          id="hotauctionscroll"
          className="tf-section tf-hot-auction tf-filter kitty__hotActionHolder"
        >
<img src={require('../assets/images/kitty/hotloud.png')} className="kitty__hotCloud"/>
        <img src={require('../assets/images/kitty/createrCloud.png')} className="kitty__createrCloud" />
        <Lottie animationData={Gradient} className="kitty__hotUFO" loop={true} />
        <Lottie animationData={UFO} className="kitty__hotleftUFO" loop={true} />
        <Lottie animationData={YellowRound} className="kitty__hotYellowRound" loop={true} />
          <div className="tf-container">
            <div className="row ">
              <div className="col-md-12">
                <div className="d-flex flex-column flex-sm-row justify-content-between align-items-end align-items-sm-start gap-3 mb-5">

                <div
                  className="tf-heading style-3 mb23 wow fadeInUp"
                >
                  <h3 className="heading">Kitty Hot Auction</h3>
                  {/* <p className="sub-heading">The Most Creative Creator - Based on the last 30 days </p> */}
                  <p className="sub-heading">
                    Explore our Hot Auctions for coveted NFTs, from rare works
                    by renowned artists to emerging talents. Experience the
                    excitement as collectors bid on digital masterpieces.{" "}
                  </p>
                </div>
                <div id="item_category2" className="dropdown">
                    <Link to="#" className="btn-selector nolink allshow">
                      {filterview.auction}
                    </Link>
                    <ul className="show allshow">
                      <li
                        onClick={() => {
                          AuctionFilter("Recent", "Recently created");
                        }}
                      >
                        <span>Recently created</span>
                      </li>
                      <li
                        onClick={() => {
                          AuctionFilter("BLTH", "Bid Low to High");
                        }}
                      >
                        <span>Bid Low to High</span>
                      </li>
                      <li
                        onClick={() => {
                          AuctionFilter("BHTL", "Bid High to Low");
                        }}
                      >
                        <span>Bid High to Low</span>
                      </li>
                      <li
                        onClick={() => {
                          AuctionFilter("OLD", "Oldest");
                        }}
                      >
                        <span>Oldest</span>
                      </li>
                    </ul>
                  </div>
                </div>
               
              </div>
              <div className="col-md-12">
                <div className="top-menu" data-aos="fade-up">
                  <ul className="filter-menu">
                    <li
                      className={HotAuctionData?.tab == "All" ? "active" : ""}
                      onClick={() => HotAuction(1, "All")}
                    >
                      <a>All</a>
                    </li>

                    {Categorys.map((item) => (
                      <li
                        className={
                          HotAuctionData.tab == item.label ? "active" : ""
                        }
                        onClick={() => HotAuction(item.value, item.label)}
                      >
                        <a>{item.label}</a>
                      </li>
                    ))}
                  </ul>
                 
                </div>
              </div>
            </div>
            <div className="tf-filter-container row" data-aos="fade-up">
              {hotactionstate == true ? (
                <>
                  <div className="text-centre">
                    <h3>Loading...</h3>
                    {/* {/ <p>Come back soon ! or try to browse something for you on our marketplace</p> /} */}
                    {/* <p>Nothing for ReSale at this time</p> */}
                    <div className="load-more">
                      {/* <Link to='/'><Button type="type" className="btn-load browse_marketplace" disableRipple>Browse Marketplace</Button></Link> */}
                    </div>
                  </div>
                </>
              ) : payload && HotAuctionData[HotAuctionData.tab]?.length > 0 ? (
                HotAuctionData[HotAuctionData.tab]
                  ?.slice(0, 8)
                  .map((item, index) => (
                    <div
                      className="col-xl-3 col-lg-4 col-md-6 col-sm-6 tf-loadmore 3d cyber"
                      data-aos="zoom-in-up"
                    >
                      <div className="kitty__nftCardHolder" data-aos="zoom-in-up">
                      <Card
                        product={item}
                        type="marketplace"
                        index={index}
                        LikeList={LikeList}
                        LikedTokenList={LikedTokenList}
                        setLikedTokenList={setLikedTokenList}
                        LikeForwardRef={LikeForwardRef}
                      />
                      </div>
                    </div>
                  ))
              ) : (
                <NoData />
              )}
            </div>
            {HotAuctionData[HotAuctionData.tab]?.length > 0 && (
              <div className="col-md-12 mt-5">
                <div className="btn-loadmore wow fadeInUp" data-aos="fade-up">
                  <NavLink to="/explore" className="tf-button style-8 loadmore kitty__loadMoreBtn">
                    Explore More
                    {/* <i className="far fa-long-arrow-right"></i> */}
                  </NavLink>
                </div>
              </div>
            )}
          </div>
        </section>


        <section className="tf-section tf-top-seller">  
          <div className="tf-container">
            <div className="row">
              <div className="col-md-12">
                <div
                  className="tf-heading style-3 mb40 wow fadeInUp"
                  data-aos="fade-up"
                >
                  <h3 className="heading">Our Top Kitty Creators</h3>
                  {/* <p className="sub-heading">Checkout Top Rated Creators on the NFT Marketplace </p> */}
                  <p className="sub-heading">
                    Meet artists changing the digital world. From creative
                    painters to innovative digital creators, their unique art
                    shows the strength of blockchain in supporting artists.
                  </p>
                </div>
              </div>
              {creatorstatus == false ? (
                <>
                  <div className="text-centre">
                    <h3>Loading...</h3>
                    {/* {/ <p>Come back soon ! or try to browse something for you on our marketplace</p> /} */}
                    {/* <p>Nothing for ReSale at this time</p> */}
                    <div className="load-more">
                      {/* <Link to='/'><Button type="type" className="btn-load browse_marketplace" disableRipple>Browse Marketplace</Button></Link> */}
                    </div>
                  </div>
                </>
              ) : 
              
              TopCreator?.length > 0 ? (
                TopCreator?.map((data, index) => {
                  return (
                    <div className="col-xl-2 col-lg-3 col-sm-6 col-md-6 mb-5">
                      <div className="d-flex flex-column card1">
                        <NavLink
                          to={`/profile/${data?.CustomUrl}`}
                          className="text-center"
                        >
                          <img
                            src={
                              data?.Profile
                                ? `${config.IMG_URL}/user/${data?.WalletAddress}/profile/${data?.Profile}`
                                : config.profile
                            }
                            alt="Image"
                            className="card-images"
                          />{" "}

                        </NavLink>
                        <div className="content text-center all1">
                          <div className="title">
                            {/* <NavLink to={"/my-item/" + data.CustomUrl}>  */}
                            <NavLink className='kitty__topCreatorName text-uppercase mt-2' to={`/profile/${data?.CustomUrl}`}>
                              {data?.DisplayName
                                ? data?.DisplayName
                                : address_showing(data?.WalletAddress)}
                            </NavLink>
                            {/* </NavLink> */}
                          </div>
                          <div className="price">
                            <span className="price-eth cardvalue">
                              {/* $-{data.value} */}
                            </span>
                          </div>
                        </div>
                        {/* <img src={Tick} alt="Image" width="14" height="14" className="img-fluid tick"/> */}
                      </div>

                      {/* <div className="tf-author-wrap" data-aos="fade-up">
                                <span className="number">{index+1}.</span>
                                <div className="tf-author">
                                    <div className="image">
                                    <img
                                        src={
                                        data?.Profile
                                            ? `${config.IMG_URL}/user/${data?.WalletAddress}/profile/${data?.Profile}`
                                            : require("../assets/images/author-detail-3.png")
                                        }
                                        alt="Image" className="auttor"
                                    />
                                        <img src={Tick} alt="Image" width="14" height="14" className="img-fluid tick"/>
                                            
                                    </div>
                                    <div className="content">
                                        <div className="title">
                                            <NavLink to={"/my-item/"+data.CustomUrl}> {data?.DisplayName
                              ? data?.DisplayName
                              : address_showing(
                                  data?.WalletAddress
                                )}</NavLink>
                                        </div>
                                        <div className="price">
                                            <span className="price-eth">$-{data.value}</span>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                    </div>
                  );
                })
              ) : (
                <NoData />
              )}
            </div>
          </div>
        </section>


        <section className="tf-section tf-hot-pick tf-filter">
          <div className="tf-container">
            <div className="row ">
              <div className="col-md-12">
                <div className="d-flex flex-column flex-sm-row justify-content-between align-items-end align-items-sm-start mb-5">
                <div
                  className="tf-heading style-3 mb26 wow fadeInUp"
                  data-aos="fade-up"
                >
                  <h3 className="heading">Kitty Hot Sales</h3>
                  {/* <p className="sub-heading">The Most creative Creator - Based on the last 30 Days </p> */}
                  <p className="sub-heading">
                    Dive into the world of exceptional creativity with our
                    monthly showcase of the most innovative creators on Naifty.
                    These artists have captivated our community with their
                    unique vision and groundbreaking works in the past 30 days.
                    Explore their collections and get inspired by the forefront
                    of digital artistry.
                  </p>
                </div>

                <div id="item_category2" className="dropdown">
                    <NavLink className="btn-selector nolink  allshow">
                      {filterview.sale}
                    </NavLink>
                    <ul className="show allshow">
                      <li
                        onClick={() => {
                          SaleFilter("BLTH", "Price Low to High");
                        }}
                      >
                        <span>Price Low to High</span>
                      </li>
                      <li
                        onClick={() => {
                          SaleFilter("BHTL", "Price High to Low");
                        }}
                      >
                        <span>Price High to Low</span>
                      </li>
                      <li
                        onClick={() => {
                          SaleFilter("OLD", "Oldest");
                        }}
                      >
                        <span>Oldest</span>
                      </li>
                      <li
                        onClick={() => {
                          SaleFilter("LatestDrops", "Recently Created");
                        }}
                      >
                        <span>Recently Created</span>
                      </li>
                    </ul>
                  </div>
                </div>
                
              </div>
              <div className="col-md-12">
                <div className="top-menu wow fadeInUp" data-aos="fade-up">
                  <ul className="filter-menu">
                    <li
                      className={SaleData.tab == "All" ? "active" : ""}
                      onClick={() => HotSales("All")}
                    >
                      <a>All</a>
                    </li>
                    {Categorys.map((item) => (
                      <li
                        className={SaleData.tab == item.label ? "active" : ""}
                        onClick={() => HotSales(item.label)}
                      >
                        <a>{item.label}</a>
                      </li>
                    ))}
                  </ul>
                 
                </div>
              </div>
            </div>
            <div
              className="tf-filter-container row wow fadeInUp"
              data-aos="fade-up"
            >
              {hotsalestate == true ? (
                <>
                  <div className="text-centre">
                    <h3>Loading...</h3>
                    {/* {/ <p>Come back soon ! or try to browse something for you on our marketplace</p> /} */}
                    {/* <p>Nothing for ReSale at this time</p> */}
                    <div className="load-more">
                      {/* <Link to='/'><Button type="type" className="btn-load browse_marketplace" disableRipple>Browse Marketplace</Button></Link> */}
                    </div>
                  </div>
                </>
              ) : SaleData[SaleData.tab]?.length > 0 ? (
                SaleData[SaleData.tab]?.slice(0, 8).map((item, index) => (
                  <div
                    className="col-xl-3 col-lg-4 col-md-6 col-sm-6 tf-loadmore 3d cyber"
                    data-aos="zoom-in-up"
                  >
                    <Card
                      product={item}
                      type="marketplace"
                      index={index}
                      LikeList={LikeList}
                      LikedTokenList={LikedTokenList}
                      setLikedTokenList={setLikedTokenList}
                      LikeForwardRef={LikeForwardRef}
                    />
                  </div>
                ))
              ) : (
                <NoData />
              )}
            </div>
            {SaleData[SaleData.tab]?.length > 0 && (
              <div className="col-md-12">
                <div className="btn-loadmore wow fadeInUp" data-aos="fade-up">
                  <NavLink to="/explore" className="tf-button style-8 loadmore kitty__loadMoreBtn">
                    Explore More
                    {/* <i className="far fa-long-arrow-right"></i> */}
                  </NavLink>
                </div>
              </div>
            )}
          </div>
        </section>

        {/* <section className="kitty__roadmap">
<div className="kitty__roadMapHolder">
        <Lottie animationData={Star} className="kitty__starLottie" loop={true} />
        <Lottie animationData={Gradient} className="kitty__gradientLottie" loop={true} />
        
<img src={require('../assets/images/kitty/hotloud.png')} className="kitty__hotCloud"/>
        <div className="tf-container">
        <div
                  className="tf-heading style-3 mb26 wow fadeInUp"
                  data-aos="fade-up"
                >
                  <h3 className="heading">Kitty Road Map</h3>
         
                  <p className="sub-heading">
                  The Most Creative Creator - Based on the last 30 days
                  </p>
                </div>
                </div>
                <img src={require('../assets/images/kitty/roadmap.png')} className="img-fluid kitty__roadmapImg" />
                </div>
        </section> */}
         <section className="kitty__roadmap">
<div className="kitty__roadMapHolder">
        <Lottie animationData={Star} className="kitty__starLottie" loop={true} />
        <Lottie animationData={Gradient} className="kitty__gradientLottie" loop={true} />
        
<img src={require('../assets/images/kitty/hotloud.png')} className="kitty__hotCloud"/>
        <div className="tf-container">
        <div
                  className="tf-heading style-3 mb26 wow fadeInUp"
                  data-aos="fade-up"
                >
                  <h3 className="heading">Kitty Road Map</h3>
                  {/* <p className="sub-heading">The Most creative Creator - Based on the last 30 Days </p> */}
                  <p className="sub-heading">
                  The Most Creative Creator - Based on the last 30 days
                  </p>
                </div>
                </div>
           
                {/* <img src={require('../assets/images/kitty/roadmap.png')} className="img-fluid kitty__roadmapImg" /> */}
                
                <div className="kitty_roadmp_wraper position-relative">

                  {/* start of mobile screen */}
                  <div className="tf-container">
                  <div className="roadmp_config roadmp_max_wd d-md-none">
                  <p className="roadmp_title">Configuration</p>
                  <p className="roadmp_content">
                    In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate
                  </p>
                </div>  
                <div className= "d-flex justify-content-center d-md-none ">
                  <img src={require('../assets/images/kitty/roadmapbar.png')} className="img-fluid my-5" style={{width:"25px"}}/>
                  </div>   
                <div className="roadmp_assem roadmp_max_wd d-md-none">
                  <p className="roadmp_title">Assembling</p>
                  <p className="roadmp_content">
                    In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate
                  </p>
                </div>
                <div className= "d-flex justify-content-center d-md-none ">
                  <img src={require('../assets/images/kitty/roadmapbar.png')} className="img-fluid my-5" style={{width:"25px"}}/>
                  </div>   
                <div className="roadmp_takeoff roadmp_max_wd d-md-none">
                  <p className="roadmp_title">Take Off</p>
                  <p className="roadmp_content">
                    In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate
                  </p>
                </div>
                <div className= "d-flex justify-content-center d-md-none ">
                  <img src={require('../assets/images/kitty/roadmapbar.png')} className="img-fluid my-5" style={{width:"25px"}}/>
                  </div>   
                <div className="roadmp_travel_kitty roadmp_max_wd d-md-none">
                  <p className="roadmp_title">Travel to the kitty</p>
                  <p className="roadmp_content">
                    In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate
                  </p>
                </div>
                  </div>

                  {/* end of mobile screen */}

                  {/* start of web screen */}

                <img src={require('../assets/images/kitty/roadmap.png')} className="img-fluid kitty__roadmapImg d-none d-md-block"  />
                <div className="roadmp_config roadmp_max_wd d-none d-md-block">
                  <p className="roadmp_title">Configuration</p>
                  <p className="roadmp_content">
                    In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate
                  </p>
                </div>     
                <div className="roadmp_assem roadmp_max_wd d-none d-md-block">
                  <p className="roadmp_title">Assembling</p>
                  <p className="roadmp_content">
                    In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate
                  </p>
                </div>
                <div className="roadmp_takeoff roadmp_max_wd d-none d-md-block" >
                  <p className="roadmp_title">Take Off</p>
                  <p className="roadmp_content">
                    In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate
                  </p>
                </div>
                <div className="roadmp_travel_kitty roadmp_max_wd d-none d-md-block">
                  <p className="roadmp_title">Travel to the kitty</p>
                  <p className="roadmp_content">
                    In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate
                  </p>
                </div>

                  {/* end of web screen */}

                </div>
                
                </div>
        </section>

        <section className="kitty__teamers higher__tooTop higher__bottom">
          <img src={require('../assets/images/kitty/createrCloud.png')} className="kitty__teamersCloud" />
        <div className="tf-container">
        <div
                  className="tf-heading style-3 mb26 wow fadeInUp"
                  data-aos="fade-up"
                >
                  <h3 className="heading">Our Kitty Team</h3>
                  <p className="sub-heading">
                  Welcome to our kitty team
                  </p>
                </div>

                <div className="kitty__teamsGrid">
                  <KittyTeamCard/>
                </div>
                </div>
        </section>

        <section className="kitty__tokenomics">
          <div className="tf-container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-6 mb-5 mb-md-0">
            <div
                  className="tf-heading style-3 mb26 wow fadeInUp"
                  data-aos="fade-up"
                >
                  <h3 className="heading">Tokenomics</h3>
                </div>
              <p className="m-0 kitty__tokenomicsSubHint">69 billion meme will be minted in total.</p>
              <p className="m-0 kity__tokenomicsDesc">Total Supply: 10000000000</p>
              <p className="m-0 kitty__tokenomicsHint">Presale :4.5 billion KITTY (45%) .</p>
              <p className="m-0 kitty__tokenomicsHint">Dex Liquidity: 2.2 billion KITTY (22%) </p>
              <p className="m-0 kitty__tokenomicsHint">Cex Liquidity: 1 billion KITTY (10%)</p>
              <p className="m-0 kitty__tokenomicsHint">NFT Games & Staking Rewards: 2 billion KITTY (20%)</p>
              <p className="m-0 kitty__tokenomicsHint">Team & Marketing 0.3 billion KITTY (3%)</p>
            </div>
            <div className="col-lg-7 col-md-6">
              <img src={require('../assets/images/kitty/toconomics.png')} className="img-fluid" />
            </div>
          </div>
          </div>
        </section>

        <section className="kitty__partnerBackers higher__top">
        <img src={require('../assets/images/kitty/hotloud.png')} className="kitty__backersCloud"/>
          <div className="tf-container">
          <div
                  className="tf-heading style-3 mb26 wow fadeInUp"
                  data-aos="fade-up"
                >
                  <h3 className="heading">our Kitty partner & backers</h3>
                  <p className="sub-heading">
                  Welcome to our kitty team
                  </p>
                </div>
          </div>

          <Marquee direction="ltr">
            {
              marqueeTop.map((item) => 
              <img src={item.img} className="img-fluid kitty__marqueeImages" />
              )
            }
</Marquee>

<Marquee direction="left">
            {
              marqueeTop.map((item) => 
              <img src={item.img} className="img-fluid kitty__marqueeImages" />
              )
            }
</Marquee>
        </section>

        {/* <section className="tf-section  tf-filter">
                <div className="tf-container">
                    <div className="row ">
                        <div className="col-md-12">
                            <div className="tf-heading style-3 mb28 wow fadeInUp"  data-aos="fade-up">
                                <h3 className="heading">Top Collection</h3>
                                <p className="sub-heading">The most well-known Collection - Based on the last 30 days  </p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="top-menu wow fadeInUp" data-aos="fade-up">
                                <ul className="filter-menu">
                                 <li className={category == "All" ? "active" : ""} onClick={() => CollectionByCreate('All')}>All</li>

                                    {Categorys.map(item=>  <li className={category == item.label ? "active" : ""} onClick={() => CollectionByCreate(item.label)}>{item.label}</li>)}

                                    </ul>
                                  
                                <div id="item_category3" className="dropdown" style={show == true ? {zIndex:9999} : {zIndex:"auto"}}>
                                    <Link to="#" className="btn-selector nolink ">{filterview.collection}</Link>
                                    <ul className="show">
                                        <li onClick={()=>{CollectionFilter('new','Recently created')}}><span>Recently created</span></li>
                                        <li onClick={()=>{CollectionFilter('old','Oldest')}}><span>Oldest</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tf-filter-container row wow fadeInUp"  data-aos="fade-up">
                    
                    </div>
                    </div>
                    <section className="tf-section tf-top-collection tf-filter">
                        <div className="tf-container">
                    <div className="row">
                    {CreateCollectionState[CreateCollectionState.tab]?.length > 0 ?

                    <div className="col-md-12">
                            <div className="swiper-container collection-over">
                                <div className="swiper-wrapper">
                                <OwlCarousel className='owl-theme' {...slideone}>
                                {CreateCollectionState[CreateCollectionState.tab].map((item,index) => {
                                        return ( 
                                    <>
                                    {item?.CollectionCount >= 3 &&
                                    <div className="swiper-slide">                               
                                        <div className="slider-item">
                                            <div className="sc-product style1 collection collection2" >
                                                <div className="top">
                                                    <div className="content">
                                                        <Link to ={"/collection/"+item.CollectionSymbol}>
                                                        <div className="author-cl">
                                                            <img src={item.CollectionProfileImage ? `${config.IMG_URL}/collection/${item.CollectionSymbol}/${item.CollectionProfileImage}` : require("../assets/images/add-collection.jpg")} alt="images" />
                                                        </div>
                                                        </Link>
                                                        <div className="inner">
                                                            <Link to ={"/collection/"+item.CollectionSymbol} className="name">{item.CollectionName}</Link>
                                                            <div className="create">created by <Link to={"/profile/"+item.CustomUrl}>{item.DisplayName ? item.DisplayName : address_showing(item.CollectionCreator)}</Link></div>
                                                        </div>
                                                    </div>
                                                    <div className="wish-list">
                                                        <Link to="#" className="heart-icon"></Link>
                                                    </div>
                                                </div>
                                     
                                                    <div className="thumb-collection">
                                                       {item?.Tokens[0]&&
                                                        <div className="left-thumb">
                                                            <Link to={item?.Tokens[0]?.Link}>                                                                                                                        
                                                           <ImgAudVideo
                                                                classname="images"
                                                                file={`${config.IMG_URL}/nft/${item?.CollectionCreator}/Compressed/NFT/${item?.Tokens[0]?.CompressedFile}`}
                                                                origFile={`${config.IMG_URL}/nft/${item?.CollectionCreator}/Original/NFT/${item?.Tokens[0]?.NFTOrginalImage}`}
                                                                type={
                                                                item?.Tokens[0]?.CompressedFile
                                                                    ? item?.Tokens[0]?.CompressedFile?.includes(".webp")
                                                                    ? "image"
                                                                    : item?.Tokens[0]?.CompressedFile.includes(".webm")
                                                                    ? "video"
                                                                    : "audio"
                                                                    : item?.Tokens[0]?.CompressedFile
                                                                }
                                                           />
                                                           </Link>
                                                        </div>}
                                                        {item?.Tokens[1]&&
                                                        <div className="right-thumb">
                                                            {item?.Tokens[1]
                                                            &&<div className="top-cl">
                                                            <Link to={item?.Tokens[1]?.Link}>
                                                            <ImgAudVideo
                                                                classname="images"
                                                                file={`${config.IMG_URL}/nft/${item?.CollectionCreator}/Compressed/NFT/${item?.Tokens[1]?.CompressedFile}`}
                                                                origFile={`${config.IMG_URL}/nft/${item?.CollectionCreator}/Original/NFT/${item?.Tokens[1]?.NFTOrginalImage}`}
                                                                type={
                                                                item?.Tokens[1]?.CompressedFile
                                                                    ? item?.Tokens[1]?.CompressedFile?.includes(".webp")
                                                                    ? "image"
                                                                    : item?.Tokens[1]?.CompressedFile.includes(".webm")
                                                                    ? "video"
                                                                    : "audio"
                                                                    : item?.Tokens[1]?.CompressedFile
                                                                }
                                                           />
                                                           </Link>
                                                            </div>}
                                                            {item?.Tokens[2]
                                                            &&<div className="bottom-cl">
                                                            <Link to={item?.Tokens[2]?.Link}>
                                                            <ImgAudVideo
                                                                classname="images"
                                                                file={`${config.IMG_URL}/nft/${item?.CollectionCreator}/Compressed/NFT/${item?.Tokens[2]?.CompressedFile}`}
                                                                origFile={`${config.IMG_URL}/nft/${item?.CollectionCreator}/Original/NFT/${item?.Tokens[2]?.NFTOrginalImage}`}
                                                                type={
                                                                item?.Tokens[2]?.CompressedFile
                                                                    ? item?.Tokens[2]?.CompressedFile?.includes(".webp")
                                                                    ? "image"
                                                                    : item?.Tokens[2]?.CompressedFile.includes(".webm")
                                                                    ? "video"
                                                                    : "audio"
                                                                    : item?.Tokens[2]?.CompressedFile
                                                                }
                                                           />
                                                           </Link>
                                                            </div>}
                                                        </div>
                                                        }
                                                    </div>
                                    
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    </>
                                    )})}
                                </OwlCarousel>   
                                </div>
                            </div>
                        </div>
                        :    <NoData/>
                    }
                    {CreateCollectionState[CreateCollectionState.tab]?.length > 0 && 
                    <div className="col-md-12">
                        <div className="btn-loadmore wow fadeInUp" data-aos="fade-up">
                            <NavLink to="/collectionlist" className="tf-button style-8 loadmore">Explore More 
                            </NavLink>
                        </div>
                    </div>}
                    </div>
                    </div>
                    </section>
            </section> */}

       

       

        <section className="tf-section tf-blog-pick tf-filter">
          <div className="tf-container">
            <div className="row ">
              <div className="col-md-12">
                <div
                  className="tf-heading style-3 mb26 wow fadeInUp"
                  data-aos="fade-up"
                >
                  <h3 className="heading">Kitty blogs</h3>
                  <p className="sub-heading">
                  Blog Content
                  </p>
                </div>
              </div>
            </div>
            <section className="aboutcarousel aboutblog pt-0 pb-5">
              {!blogstate ? (
                <div className="text-centre">
                  <h3>Loading...</h3>
                  {/* {/ <p>Come back soon ! or try to browse something for you on our marketplace</p> /} */}
                  {/* <p>Nothing for ReSale at this time</p> */}
                  <div className="load-more">
                    {/* <Link to='/'><Button type="type" className="btn-load browse_marketplace" disableRipple>Browse Marketplace</Button></Link> */}
                  </div>
                </div>
              ) : blog.length > 0 ? (
                <div className="fullwidthcarousel1">
                  <OwlCarousel className="owl-theme" {...options1}>
                    {blog.map((e, i) => (
                      <div class="item">
                        <Link to={`/blogdetail/${e?.url}`} state={{ item: e }}>
                          <div class="card mt-3">
                            <img
                              class="card-img-top"
                              src={`${config.IMG_URL}/${e.img}`}
                              alt="Card image cap"
                            />
                            <div class="card-body">
                              <h5 class="card-title">{e.heading}</h5>
                              <p className="grays mb-4">
                                Published By{" "}
                                {e.date &&
                                  `${new Date(e?.date).toLocaleString(
                                    "default",
                                    { month: "long" }
                                  )} ${new Date(e?.date).getDate()},${new Date(
                                    e?.date
                                  ).getFullYear()}`}
                              </p>
                              <p>
                                <div
                                  className="contetn_forn_ad_car"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      e?.content?.length > 100
                                        ? e?.content.slice(0, 200)
                                        : e?.content,
                                  }}
                                />
                              </p>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              ) : (
                <NoData />
              )}

              <div className="btn-loadmore mt-5 wow fadeInUp">
                <NavLink to="/blog" className="tf-button style-8 loadmore kitty__loadMoreBtn mt-5">
                  Learn More
                  {/* <i className="far fa-long-arrow-right"></i> */}
                </NavLink>
              </div>
            </section>
          </div>
        </section>

        <Footer />
      </div>
    </>
  );
}
