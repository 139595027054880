import { Route, Routes, Redirect } from "react-router-dom";
import Home from "./views/Home";
import Explore from "./views/Explore";
import FAQ from "./views/FAQ";

import Blog from "./views/Blog";
import Blogdetail from "./views/Blogdetail";

import Create from "./views/Create";
import Collection from "./views/Collection";
import ConnectWallet from "./views/ConnectWallet";
import ContactUs from "./views/Contactus";
import Details from "./views/Details";
import Board from "./views/Board";
import PrivacyPolicy from "./views/PrivacyPolicy";
import Referraldetail from "./views/referraldetail";
import MyItem from "./views/my_item";
import CollectionList from "./views/CollectionList";
import  Search  from "./views/Search";

import { BrowserRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Activity from "./views/Activity";

import List from "./views/List";

import AboutUs from "./views/Aboutus";
import Referral from "./views/Referral";
import HelpandCentre from "./views/helpandcentre";
import CreateProfile from './views/BeforeloginEditProfile';
import Comingsoon from './views/comingsoon';
import './App.css'
import TermsandConditions from "./views/TermsandConditions";
import HomeWithHomeLinks from "./views/HomeWithHomeLinks";

function App() {

  console.log = function() {};
  console.error = function() {};
  console.warn = function() {};
  console.info = function() {};
  

  return (
    <>      
    <BrowserRouter basename="/">
      <Routes>
        <Route path='/explore' element={<Explore/>} />
        <Route path='/explore/:Category' element={<Explore/>} />
        <Route path='/Activity' element={<Activity/>} />
        <Route path='/faq' element={<FAQ/>} />
        <Route path='/blog' element={<Blog/>} />
        <Route path='/blogdetail/:url' element={<Blogdetail/>} />
        <Route path='/create' element={<Create/>} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/collection/:CollectionSymbol' element={<Collection/>} />
        <Route path='/connect' element={<ConnectWallet/>} />
        <Route path='/connect/:referralLink' element={<ConnectWallet/>} />
        <Route path='/search' element={<Search />} />
        <Route path='/collectionlist' element={<CollectionList />} />
        <Route path="/myitem" element={<MyItem/>} />
        <Route path='/info/:network/:Contract/:Owner/:Id' element={<Details/>} />
        <Route path='/list/:address/:id' element={<List/>} />
        <Route path='/details' element={<Details/>} />
        <Route path="/my-item/:customurl" element={<MyItem/>} />
        <Route path='/profile/:customurl' element={<Board/>} />
        <Route path='/search/:key' element={<Search/>} />
        <Route path='/' element={<Home/>} />
        {/* <Route path='/' element={<HomeWithHomeLinks/>} /> */}

        <Route path='/privacypolicy' element={<PrivacyPolicy/>} />
        <Route path='/terms' element={<TermsandConditions/>} />
        <Route path='/referraldetail' element={<Referraldetail/>} />
        <Route path='/helpandcentre' element={<HelpandCentre/>}/>
        <Route path ='/contactus' element={<ContactUs/>} />
        <Route path ='/aboutus' element={<AboutUs/>} />
        <Route path ='/createProfile/:walletAddress' element={<CreateProfile/>} />     
        {/* <Route path='/' element={<Comingsoon/>} /> */}
     
    </Routes>
    
    </BrowserRouter>

    <ToastContainer
				position="top-right"
				autoClose={1000}
				hideProgressBar={false}
				rtl={true}
				closeOnClick={true}
        closeButton={true}
				style={
					{zIndex:"999999",
					"width":"400px",
					"wordBreak":"break-word",
					"text-align":"center",
					"fontWeight":"500",
					marginTop:"70px"
				}} 
			/>    
    </>
  );
}

export default App;
