import React,{useState,useEffect} from "react";
import { Sociallinks , Newsletter} from "../actions/axioss/user.axios";
import config from "../views/config/config"
import { NavLink, Link, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { isEmpty } from "../actions/common";
import { toast } from "react-toastify";
import { IoMdMail } from "react-icons/io";
import discard from "../assets/images/DiscordLogo.png";
import whatsapp from '../assets/images/whatsapp (1).png'
import facebook from '../assets/images/facebook.png'
import telegram from '../assets/images/telegram.png'
import linkedin from '../assets/images/linkedin.png'
import you from "../assets/images/YoutubeLogo.png";
import twitter from "../assets/images/TwitterLogo.png";
import instagram from "../assets/images/InstagramLogo.png";
import { getCmsContent } from "../actions/axioss/cms.axios";
import { PiDiscordLogo } from "react-icons/pi";
import { PiYoutubeLogoLight } from "react-icons/pi";
import { FaXTwitter, FaInstagram } from "react-icons/fa6";

export default function Footer(props){
    var [email,setEmail] = useState('');
    var [Error,SetError] = useState({});
    // const navigate  = useNavigate();

    // const [click, setClick] = useState(false);
    // const gotoLive = () =>    
    // {
    //     setClick(true);
    //     setTimeout(()  =>
    //     {
    //         navigate("/");
    //         if((!isEmpty(props)))
    //         {
    //             console.log(click,"click")
    //             props?.func("liveauction",true)
          
            
    //     },1000);
      
    // }<FaXTwitter />
    // }
    const scrollTo = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          })
      }
      const wallet = useSelector(state => state.LoginReducer.AccountDetails);
    var [link,setLink] = useState([]);
    var [Error,SetError] = useState({});
  const [footer,setFooter]=useState({})
    const { payload } = useSelector(state => state.LoginReducer.User)

      const push = useNavigate()
    useEffect(()=>{
        var a = Getlink();
        Getandsellnft();
        // console.log("hello",a)
    },[])
    const Getandsellnft = async () => {

        var resp = await getCmsContent("footer");
        console.log("footerrr",resp?.data)
        if (resp?.status)
            setFooter(resp.data);
    }

    const Getlink=async()=>{
        
        let link_res= await Sociallinks();
        console.log("linkss",link_res?.msg);
        setLink(link_res?.msg??[])
    }

    const NewsLetter = async() =>{
        const id = toast.loading("Subscribing...");
        
        let err = {};
        if(email=='') err.email="Email Id Required";
        if(email && !(config.EMAIL).test(email)) err.email = 'Invalid Email ID Format';
        SetError(err);
        // console.log('fhbdfhbdf',email)
        if(isEmpty(err)){
            let resp = await Newsletter({email : email});
            if(resp.success === 'success'){
                toast.update(id ,{render:"Successfully Subscribed", type: "success", isLoading: false, autoClose: 1000,closeButton:true,closeOnClick:true})
                setEmail('');
            }
            else{
                toast.update(id ,{ render : "Already User", type :'error', isLoading: false, autoClose: 1000,closeButton:true,closeOnClick:true})
                SetError({email:resp.msg});
            }
        }
        else{
            toast.update(id ,{ render : err.email, type :'error', isLoading: false, autoClose: 1000,closeButton:true,closeOnClick:true})
        }
    }

    return(
        <>
          <footer className="footer kitty__footer higher__top footer_new">
          <img src={require('../assets/images/kitty/footerCloud.png')} className="kitty__footerRighttCloud" />

          <img src={require('../assets/images/kitty/createrCloud.png')} className="kitty__footerLeftCloud" />
                <div className="tf-container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-6">
                            <div className="widget widget-infor mb-3">
                               <img src={require('../assets/images/kitty/logo.png')} className="img-fluid kitty__footerLogo mb-5" />
                                <div className="subheads" dangerouslySetInnerHTML={{ __html: footer?.answer }}></div>

                                <div className="kitty__footerCommunity mt-5">
                                    <p className="m-0 kitty__footerHint">Join our community</p>
                                    <div className="kitty__socialFlexer d-flex justify-content-start align-items-center">
                                    <PiDiscordLogo className="pointer" />
                                    <PiYoutubeLogoLight className="pointer" />
                                    <FaXTwitter className="pointer" />
                                    <FaInstagram className="pointer" />
                                    </div>
                                </div>
                               
                                {/* <p className="content">NFT marketplace UI created with Anima for Figma.</p> */}
                             
                                {/* <ul className="social-item">
                                  
                                {link.map((mlink)=>(  
                                     
                                    <>
                                    
                                    {
                               (mlink.website).toLowerCase()==="twitter" &&
                                    <a href={mlink.link} target="blank">
                                        
                                        <img src={twitter} alt="dd"/>
                                        </a>
                                        }&nbsp;
                                   { 
                                   (mlink.website).toLowerCase()==="youtube" &&
                                    <a href={mlink.link} target="blank">
                                        <img src={you} alt="dd"/>
                                       
                                        </a>
                                        }
                                        &nbsp;
                                        {(mlink.website).toLowerCase() ==="instagram" &&
                                    <a href={mlink.link} target="blank">
                                        
                                        <img src={instagram} alt="dd"/>
                                        </a>
                                        }&nbsp;
                                    {
                                    (mlink.website).toLowerCase()==="discard" &&
                                    <a href={mlink.link} target="blank">
                                      
                                        <img src={discard} alt="dd"/>
                                        </a>
                                        }
                                    </>
                                    ))}
                                </ul> */}
                                {/* <p className="copy-right">Copyright © 2022 Ditmax. All Rights Reserved.</p> */}
                            </div>
                            {/* <a href="mailto:support@naifty.io" className="footermailtag d-flex align-items-center"><IoMdMail className="mailcon"/> <span className="ml-2"> support@naifty.io</span></a> */}
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="widget widget-menu">
                                <div className="menu menu-1">
                                    <h6 className="widget-title spaceMono">Marketplace</h6>
                                    <ul >
                                        <li><Link to="/explore">Explore</Link></li>
                                        {/* <li><Link to="/">Collection</Link></li> */}
                                   
                                   {wallet && wallet.accountAddress && <li><Link to="/create">Create</Link></li> }     
                                    
                                        {/* <li><Link to="#" onClick={()=>{push ('/')
document.getElementById('liveauction').scrollTo({behaviour:"smooth"})
}}>Live Auction</Link></li> */}
                                {/* <li><Link to="/"
                                >Live Auction</Link></li> */}

                                    </ul>
                                </div>
                                <div className="menu menu-2">
                                    <h6 className="widget-title spaceMono">Stats</h6>
                                    <ul >
                                        {/* <li><Link to="#">Ranking</Link></li> */}
                                        {/* <li><Link to={payload?.CustomUrl ? `/profile/${payload?.CustomUrl}`: "#"}>Activity</Link></li> */}
                                        <li><Link to={payload?.CustomUrl ? `/profile/${payload?.CustomUrl}`: "#"}>Activity</Link></li>
                                        <li><Link to={payload?.CustomUrl ? `/profile/${payload?.CustomUrl}`: "#"}>Profile</Link></li>
                                        {/* <li><Link to={`/profile/${payload?.CustomUrl}`}>Profile</Link></li> */}
                                    </ul>
                            </div>
                                <div className="menu menu-3">
                                    <h6 className="widget-title spaceMono">Resoucre</h6>
                                    <ul>
                                        {/* <li><Link to="#">Blogs</Link></li> */}
                                        {/* <li><Link to="/helpandcentre">Help and Center</Link></li> */}
                                        <li><Link to="/privacypolicy">Privacy</Link></li>
                                        <li><Link to="/faq">Faq</Link></li>
                                        {/* <li><Link to="/contactus">Contactus</Link></li> */}
                                        <li><Link to="/aboutus">AboutUs</Link></li>
                                        <li><Link to="/blog">Blog</Link></li>
                                        <li><Link to="/terms">Terms & Conditions</Link></li>
                                    </ul>
                                </div>
                                {wallet && wallet.accountAddress ?
                                <div className="menu menu-4">

                                    <h6 className="widget-title spaceMono">My account</h6>
                                    <ul >
                                        {/* <li><Link to={payload?.CustomUrl ? `/profile/${payload?.CustomUrl}`: "#"}>Profile</Link></li> */}
                                        <li><Link to={`/profile/${payload?.CustomUrl}`}>Profile</Link></li>
                                        {/* <li><Link to="/connect">Wallet</Link></li> */}
                                        <li><Link to={`/profile/${payload?.CustomUrl}`} state={{Tab : 'owned'}}>My item</Link></li>

                                    </ul>
                                </div> :  <div className="menu menu-4">

<h6 className="widget-title spaceMono">My account</h6>
<ul >
    {/* <li><Link to={payload?.CustomUrl ? `/profile/${payload?.CustomUrl}`: "#"}>Profile</Link></li> */}
    <li>Profile</li>
    {/* <li><Link to="/connect">Wallet</Link></li> */}
    <li>My item</li>

</ul>
</div>}
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-12">
                            <div className="widget widget-subcribe">
                                <h6 className="widget-title spaceMono">Subscribe Us</h6>
                                <p className="content">Get exclusive promotions & updates straight to your inbox.</p>
                                <form id="subscribe-form">
                                    <input type="email" placeholder="example@yourmail.com" value={''||email} onChange={(event)=>{setEmail(event.target.value)}} required="" id="subscribe-email" className="footer_email_inpt" autoComplete="off" />
                                    <button className="tf-button kitty__footerSubBtn" type="button" id="subscribe-button" onClick={NewsLetter}>
                                        {/* <i className="icon-fl-send"></i> */}Subscribe
                                        </button>
                                </form>

                               
                                {/* <p className="join">Join our community</p> */}
                              
                            </div>
                        </div>

                        <div className="col-md-12 footer_line">
                            <div className="flex_content_footer2">
                            <p className="copy-right foot-p text-center">Copyright © 2024. All Rights Reserved.</p>
                            {/* <ul className="social-item mt-4">
                                  
                                  {link.map((mlink)=>(  
                                       
                                      <>
                                      {
                                         <a href={mlink.link} target="blank">
                                          
                                         <img src={`${config.IMG_URL}/socialimg/${mlink?.img}`} alt="dd"/>
                                         </a>
                                      }
                                      
                                      {
                                 (mlink.website).toLowerCase()==="twitter" &&
                                      <a href={mlink.link} target="blank">
                                          
                                          <img src={twitter} alt="dd"/>
                                          </a>
                                          }
                                     { 
                                     (mlink.website).toLowerCase()==="youtube" &&
                                      <a href={mlink.link} target="blank">
                                          <img src={you} alt="dd"/>
                                         
                                          </a>
                                          }
                                          
                                          {(mlink.website).toLowerCase() ==="instagram" &&
                                      <a href={mlink.link} target="blank">
                                          
                                          <img src={instagram} alt="dd"/>
                                          </a>
                                          }
                                      {
                                      (mlink.website).toLowerCase()==="discard" &&
                                      <a href={mlink.link} target="blank">
                                        
                                          <img src={discard} alt="dd"/>
                                          </a>
                                          }
                                          {
                                      (mlink.website).toLowerCase()==="whatsapp" &&
                                      <a href={mlink.link} target="blank">
                                        
                                          <img src={whatsapp} width={20} alt="dd"/>
                                          </a>
                                          }
                                          {
                                      (mlink.website).toLowerCase()==="telegram" &&
                                      <a href={mlink.link} target="blank">
                                        
                                          <img src={telegram} width={20} alt="dd"/>
                                          </a>
                                          }
                                          {
                                      (mlink.website).toLowerCase()==="linkedin" &&
                                      <a href={mlink.link} target="blank">
                                        
                                          <img src={linkedin}  width={20} alt="dd"/>
                                          </a>
                                          } 
                                          {
                                      (mlink.website).toLowerCase()==="facebook" &&
                                      <a href={mlink.link} target="blank">
                                        
                                          <img src={facebook}  width={20}alt="dd"/>
                                          </a>
                                          }
                                      </>
                                      ))}
                                  </ul> */}
                            </div>
                       
                        </div>
                    </div>
                </div>
                <Link to="#" id="scroll-top" className="scrolltop_zindex" onClick={scrollTo}></Link>
           </footer>
        </>
    )
}